import Cookies from 'js-cookie'
import useSWR, { preload } from 'swr'
import { useMemo, useState } from 'react'
import { useRouter } from 'next/router'

import { Collection as CollectionType } from 'lib/shopify/types'
import fetchGraphqlApi from 'lib/config/fetch-graphql-api'
import { getCollectionQuery } from 'lib/shopify/queries/collectionByHandle'
import { normalizeCategory } from 'lib/utils/normalize'

export const validSortKey = ['PRICE', 'RELEVANCE']

preload('/api/user', getCollection)

export async function getCollection(input: CollectionType.CollectionQuery) {
  const { handle, locale, queryArgument } = input

  const fetchGraphqlApiHandle = async (cHandle: string) => {
    return await fetchGraphqlApi<CollectionType.GetCollectionOperation>({
      locale,
      query: getCollectionQuery,
      variables: {
        handle: cHandle,
        first: queryArgument?.first,
        last: queryArgument?.last,
        after: queryArgument?.after,
        before: queryArgument?.before,
        reverse: queryArgument?.reverse,
        filters: queryArgument?.filters,
        sortKey: queryArgument?.sortKey || 'COLLECTION_DEFAULT',
      },
      cache: 'no-store',
    })
  }

  if (Array.isArray(handle)) {
    const collections = await Promise.allSettled(
      handle.map(async (cHandle) => {
        const { res } = await fetchGraphqlApiHandle(cHandle)
        return res?.collectionByHandle
      })
    )

    return collections.map((result) =>
      result.status === 'fulfilled' && result.value
        ? normalizeCategory(result.value)
        : null
    )
  } else {
    const { res } = await fetchGraphqlApiHandle(handle as string)
    const collectionByHandle = res?.collectionByHandle

    return collectionByHandle ? normalizeCategory(collectionByHandle) : null
  }
}

export function useFilterCollection(
  handle: string | string[],
  queryArgument: CollectionType.CollectionQuery,
  initData?: any
) {
  if (Array.isArray(handle) && handle.length <= 0) return { data: [] }
  const { locale = 'us' } = useRouter()

  const response = useSWR({ locale, handle, queryArgument }, getCollection, {
    keepPreviousData: true,
    fallbackData: initData,
  })

  let isLoading = false

  if (Array.isArray(response?.data)) {
    isLoading = response.isLoading
  } else {
    isLoading = response.isLoading && !response?.data?.initData
  }

  return useMemo(
    () =>
      Object.defineProperties(response, {
        isEmpty: {
          value: !response.data,
          enumerable: true,
        },
        isLoading: {
          value: isLoading,
          enumerable: true,
        },
      }),
    [response]
  )
}
