import BannerLink from '@components/common/BannerLink'
import { Picture, Text, useUI, ButtonV2 } from '@components/ui'
import usePrice, { formatPrice } from '@commerce/product/use-price'
import { useRouter } from 'next/router'
import { atobID } from '@lib/utils/tools'
import cn from 'classnames'
import { LoginPopTab } from '@components/ui/context'
import { pageGTMEvent } from '@lib/utils/thirdparty'
import Icon from '@components/icons'

const Index = ({
  data,
  product,
  cns,
  isDark,
  defaultRef,
  gmtIndex,
  itemListName,
  isMember = false,
  registrationsSettings,
  isLogin = false,
  loginButtonTxt,
  productNote,
  pageGroup,
}) => {
  const { locale } = useRouter()
  const tag = product.tags?.find((tag) => tag in (data?.tagToDescription || {}))
  const {
    setLoginPop,
    setLoginDefaultTab,
    setRegistrationsPopup,
    setRegistrationsModelStatus,
  } = useUI()

  const login = () => {
    if (isLogin) return
    if (registrationsSettings) {
      setRegistrationsPopup(true)
      setRegistrationsModelStatus('quickLogin')
    } else {
      setLoginDefaultTab(LoginPopTab.Login)
      setLoginPop(true)
    }
    pageGTMEvent({
      event: 'ga4Event',
      event_name: 'entry_for_register',
      event_parameters: {
        page_group: '',
        button_name: loginButtonTxt || '',
        position: itemListName || '',
      },
    })
  }

  return (
    <div
      className={cn(
        cns?.cardActive,
        cns.productCardBox,
        'ProductCard relative flex w-full flex-col items-center justify-between overflow-hidden leading-[1.2]',
        isDark ? 'bg-[#1D1D1F]' : 'bg-[#fff]'
      )}
    >
      {/* prod counpon */}
      <Discount data={data} product={product} />

      {/* prod Img */}
      <div className="relative box-border w-full overflow-hidden px-[24px] pb-[2px] pt-[30px]">
        <Picture
          className={cn(
            'aspect-h-[244] aspect-w-[386] w-full md:aspect-h-[132] md:aspect-w-[206]',
            cns?.cardImage
          )}
          source={`${product.image}, ${
            product.mobileImage || product.image
          } 769`}
          imgClassName="h-full w-full object-contain"
        />
      </div>

      {/* prod Info */}
      <div className="relative box-border w-full p-[24px] pt-0 leading-[1.4] xl:p-[16px] xl:pt-0">
        {/* tags */}
        <div className="mb-[4px] min-h-[22px] xl:mb-[2px] xl:min-h-[20px]">
          {product?.customizeTags ? (
            <div className="flex flex-wrap justify-start gap-[8px]">
              {product?.customizeTags?.map((tag, index) => {
                return (
                  <span
                    key={index}
                    className="text-[16px] font-[500] text-[#FF9900] xl:text-[14px]"
                  >
                    {tag?.toUpperCase()}
                  </span>
                )
              })}
            </div>
          ) : (
            <div className="flex flex-wrap justify-start gap-[8px]">
              <Text
                html={
                  data.tagToDescription?.[tag]?.label?.toUpperCase() ||
                  data.tagToDescription?.[tag]?.toUpperCase() ||
                  ''
                }
                className="text-[16px] font-[500] text-[#FF9900] xl:text-[14px]"
                variant="paragraph"
              />
            </div>
          )}
        </div>
        <Text
          html={
            product.custom_title ||
            product?.variantMetafields?.infos?.name ||
            product.title
          }
          className={cn(
            'line-clamp-2 min-h-[56px] text-[20px] font-[500] xl:min-h-[44px] xl:text-[16px]',
            isDark ? 'text-[#fff]' : 'text-[#1D1D1F]'
          )}
        />
        <Text
          html={
            productNote ||
            product?.note ||
            product?.variantNote ||
            product?.metafields?.productNote
          }
          className={cn(
            'mt-[4px] line-clamp-1 min-h-[22px] text-[16px] font-[500] text-[#777] xl:mt-[2px] xl:min-h-[20px] xl:text-[14px]',
            isDark ? 'text-[#999]' : 'text-[#777]'
          )}
        />
        <div className="mt-[32px] flex items-center xl:mt-[16px]">
          <Price data={data} product={product} isDark={isDark} />
          {product?.availableForSale && (
            <Icon
              iconKey="arrowRight"
              width={24}
              height={24}
              className={cn(
                'mb-[3px] [&_path]:stroke-[#1D1D1F]',
                isDark && '[&_path]:stroke-[#fff]'
              )}
            ></Icon>
          )}
        </div>
        <div
          className={cn(
            cns.aniButton,
            'relative z-[4] h-[0px] overflow-hidden transition-all duration-[0.4s]',
            isMember ? 'md:h-auto' : 'md:hidden'
          )}
        >
          <ButtonV2 className="mt-[12px] !h-[40px] !min-w-[136px] !text-[14px] !leading-[40px] md:mt-[8px]">
            <a
              href={
                isMember
                  ? isLogin
                    ? `${locale === 'us' ? '' : '/' + locale}/products/${
                        product.handle
                      }?variant=${atobID(product.variantId)}&ref=${defaultRef}`
                    : null
                  : `${locale === 'us' ? '' : '/' + locale}/products/${
                      product.handle
                    }?variant=${atobID(product.variantId)}&ref=${defaultRef}`
              }
              onClick={() => {
                isMember
                  ? isLogin
                    ? pageGTMEvent({
                        event: 'ga4Event',
                        event_name: 'select_item',
                        event_parameters: {
                          page_group:
                            pageGroup || 'Product Detail Page_' + product?.sku,
                          currency: product?.currencyCode || '',
                          item_list_name: itemListName || '',
                          items: [
                            {
                              item_id: product?.sku || '', //SKU
                              item_name: product?.title || '',
                              item_brand: product?.brand || '',
                              item_category: product?.collection || '', //产品品类
                              item_variant: product?.variantName || '',
                              price: product?.price || '',
                              index: gmtIndex || '',
                            },
                          ],
                        },
                      })
                    : login()
                  : pageGTMEvent({
                      event: 'ga4Event',
                      event_name: 'select_item',
                      event_parameters: {
                        page_group:
                          pageGroup || 'Product Detail Page_' + product?.sku,
                        currency: product?.currencyCode || '',
                        item_list_name: itemListName || '',
                        items: [
                          {
                            item_id: product?.sku || '', //SKU
                            item_name: product?.title || '',
                            item_brand: product?.brand || '',
                            item_category: product?.collection || '', //产品品类
                            item_variant: product?.variantName || '',
                            price: product?.price || '',
                            index: gmtIndex || '',
                          },
                        ],
                      },
                    })
              }}
            >
              {loginButtonTxt || data?.buyButtonTxt || data?.buy_now}
            </a>
          </ButtonV2>
        </div>
      </div>

      {isMember ? (
        <a
          className="absolute left-0 top-0 h-full w-full cursor-pointer"
          onClick={() => {
            !isLogin
              ? login()
              : pageGTMEvent({
                  event: 'ga4Event',
                  event_name: 'select_item',
                  event_parameters: {
                    page_group:
                      pageGroup || 'Product Detail Page_' + product?.sku,
                    currency: product?.currencyCode || '',
                    item_list_name: itemListName || '',
                    items: [
                      {
                        item_id: product?.sku || '', //SKU
                        item_name: product?.title || '',
                        item_brand: product?.brand || '',
                        item_category: product?.collection || '', //产品品类
                        item_variant: product?.variantName || '',
                        price: product?.price || '',
                        index: gmtIndex || '',
                      },
                    ],
                  },
                })
          }}
          href={
            !isLogin
              ? null
              : `${locale === 'us' ? '' : '/' + locale}/products/${
                  product.handle
                }?variant=${atobID(product.variantId)}&ref=${defaultRef}`
          }
        ></a>
      ) : (
        <BannerLink
          to={`${locale === 'us' ? '' : '/' + locale}/products/${
            product.handle
          }?variant=${atobID(product.variantId)}&ref=${defaultRef}`}
          onClick={() => {
            pageGTMEvent({
              event: 'ga4Event',
              event_name: 'select_item',
              event_parameters: {
                page_group: pageGroup || 'Product Detail Page_' + product?.sku,
                currency: product?.currencyCode || '',
                item_list_name: itemListName || '',
                items: [
                  {
                    item_id: product?.sku || '', //SKU
                    item_name: product?.title || '',
                    item_brand: product?.brand || '',
                    item_category: product?.collection || '', //产品品类
                    item_variant: product?.variantName || '',
                    price: product?.price || '',
                    index: gmtIndex || '',
                  },
                ],
              },
            })
          }}
        ></BannerLink>
      )}
    </div>
  )
}

export default Index

const Discount = ({ data, product }) => {
  const { locale } = useRouter()
  if (product?.hideDiscount) return
  if (
    ((!product?.discountValue || product?.discountValue <= 0) &&
      !product?.fakeDiscount) ||
    !product?.availableForSale
  )
    return null
  return (
    <div className="absolute left-0 top-0 z-[1]">
      <div className="relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="80"
          height="28"
          viewBox="0 0 80 28"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M80 0.0227581V0H0V28H66.0511L80 0.0227581Z"
            fill="#DAEDFF"
          />
        </svg>

        <div
          className="absolute left-0 top-0 box-border flex h-full w-full items-center justify-center gap-[5px] pr-[10px] pt-[2px] leading-[1.4]"
          style={{
            background: 'linear-gradient(90deg, #0AF 0%, #0082FF 50%)',
            backgroundClip: 'text',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
          }}
        >
          {product?.fakeDiscount && (
            <p className="text-[14px] font-[600]">{product.fakeDiscount}</p>
          )}
          {!product?.fakeDiscount && product?.discountValue && (
            <p className="text-[14px] font-[600]">
              {['fixed_amount', 'fix_discount_amount', 'fix_amount'].includes(
                product?.discountType
              )
                ? (locale == 'fr' ? '- ' : '') +
                  formatPrice({
                    amount: parseInt(product.discountValue),
                    locale,
                    currencyCode: product.currencyCode,
                    maximumFractionDigits: 0,
                  })
                : (locale == 'fr' ? '- ' : '') +
                  parseInt(product.discountValue) +
                  '%'}
            </p>
          )}
          <p className="text-[14px] font-[600]">{data?.off}</p>
        </div>
      </div>
    </div>
  )
}

const Price = ({ data, product, isDark }) => {
  const { locale } = useRouter()
  const basePrice = usePrice({
    amount: Number(product?.basePrice || 0),
    currencyCode: product?.currencyCode || 'USD',
  })

  return (
    <div className="flex items-baseline gap-[8px]">
      {product?.availableForSale ? (
        <>
          {product?.basePrice && product?.basePrice > product?.price ? (
            <span
              className={cn(
                'text-[16px] font-[500] line-through xl:text-[14px]',
                isDark ? 'text-[#999]' : 'text-[#777]'
              )}
            >
              {basePrice.price}
            </span>
          ) : null}
          <span
            className={cn(
              'p-0 text-[20px] font-[600] xl:text-[16px]',
              isDark ? 'text-[#fff]' : 'text-[#1D1D1F]'
            )}
          >
            {product?.fakePrice ||
              formatPrice({
                amount: Number(product?.price || 0),
                currencyCode: product?.currencyCode || 'USD',
                locale,
              })}
          </span>
        </>
      ) : (
        <span
          className={cn(
            'text-[20px] font-[600] xl:text-[16px]',
            isDark ? 'text-[#999]' : 'text-[#777]'
          )}
        >
          {data?.soldOut}
        </span>
      )}
    </div>
  )
}
