import fetcher from '@lib/fetcher'
import UAParser from 'ua-parser-js'
import { I18N_STORE_DOMAIN } from '@shopify/const'

const getProductPersonalizeInHome = async ({
  locale,
  clientId,
  limit, //条数限制
  formatData,
  filter_values = [],
}) => {
  const parser = new UAParser()
  const userAgent = parser.getResult()
  const url = `/api/multipass/rainbowbridge/personalize/relation`

  const countryCode_result = await fetcher({ url: '/geolocation' })

  const result = await fetcher({
    url,
    locale,
    'Content-Type': 'application/json',
    method: 'POST',
    body: {
      shopify_domain: I18N_STORE_DOMAIN[locale],
      user_id: clientId || '',
      country: countryCode_result?.geo?.country?.code || locale,
      limit,
      device_type: userAgent?.device?.type || userAgent?.os?.name,
      browser_type: userAgent?.browser?.name,
      filter_values,
    },
  })
  if (!result?.errors && result?.data) {
    const resultData = result?.data
    if (formatData) {
      return formatData(resultData)
    } else {
      return resultData
    }
  } else {
    return false
  }
}

export default getProductPersonalizeInHome
