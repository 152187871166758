import metafieldsItems from '../fragments/metafields'

export const getCollectionQuery = /* GraphQL */ `
  query getCollectionByHandle(
    $handle: String!
    $first: Int = 50
    $last: Int
    $sortKey: ProductCollectionSortKeys
    $filters: [ProductFilter!]
    $reverse: Boolean
    $after: String
    $before: String
  ) {
    collectionByHandle(handle: $handle) {
      id
      handle
      title
      description
      seo {
        title
        description
      }
      image {
        id
        width
        height
        altText
        url
      }
      products (
        first: $first, 
        last: $last,  
        after: $after, 
        before: $before, 
        reverse: $reverse, 
        sortKey: $sortKey, 
        filters: $filters, 
      ) {
        pageInfo {
          endCursor
          hasNextPage
          hasPreviousPage
          startCursor
        }
        filters {
          id
          label
          type
          values {
            count
            input
            label
            id
          }
        }
        edges {
          node {
            id
            title
            vendor
            handle
            tags
            description
            descriptionHtml
            productType
            onlineStoreUrl
            totalInventory
            availableForSale
            priceRange {
              minVariantPrice {
                amount
                currencyCode
              }
            }
            compareAtPriceRange {
              minVariantPrice {
                amount
                currencyCode
              }
            }
            options {
              id
              name
              values
            }
            images(first: 100) {
              edges {
                node {
                  url
                  altText
                  width
                  height
                }
              }
            }
            MF_preRender: metafield(namespace: "build", key: "preRender") {
              ${metafieldsItems}
            }
            MF_seoSetting: metafield(namespace: "seo", key: "setting") {
              ${metafieldsItems}
            }
            MF_productInfos: metafield(namespace: "product", key: "infos") {
              ${metafieldsItems}
            }
            MF_productTags: metafield(namespace: "infos", key: "productTags") {
              ${metafieldsItems}
            }
            MF_productNote: metafield(namespace: "infos", key: "productNote") {
              ${metafieldsItems}
            }
            MF_judgemeBadge: metafield(namespace: "judgeme", key: "badge") {
              ${metafieldsItems}
            }
            MF_judgemeWidget: metafield(namespace: "judgeme", key: "widget") {
              ${metafieldsItems}
            }
            MF_images: metafield(namespace: "product", key: "images") {
							${metafieldsItems}
            }
            variants(first: 30) {
              edges {
                node {
                  id
                  title
                  sku
                  availableForSale
                  requiresShipping
                  quantityAvailable
                  currentlyNotInStock
                  image {
                    altText
                    url
                    width
                    height
                  }
                  selectedOptions {
                    name
                    value
                  }
                  priceV2 {
                    amount
                    currencyCode
                  }
                  compareAtPriceV2 {
                    amount
                    currencyCode
                  }
                  MF_infos: metafield(namespace: "variant", key: "infos") {
                    ${metafieldsItems}
                  }
                  MF_images: metafield(namespace: "variant", key: "images") {
                    ${metafieldsItems}
                  }
                  MF_seoSetting: metafield(namespace: "seo", key: "setting") {
                    ${metafieldsItems}
                  }
                  MF_variantTags: metafield(namespace: "filter", key: "tags") {
                    ${metafieldsItems}
                  }
                  MF_gallery: metafield(namespace: "variant", key: "gallery") {
                    ${metafieldsItems}
                  } 
                }
              }
            }
          }
        }
      }
      MF_template : metafield(namespace: "template", key: "name") {
        ${metafieldsItems}
      }
      MF_data: metafield(
        namespace: "global"
        key: "data"
      ) {
          ${metafieldsItems}
      }
      MF_faq: metafield(
        namespace: "content"
        key: "faq"
      ) {
          ${metafieldsItems}
      }
      MF_name: metafield(
        namespace: "content"
        key: "name"
      ) {
          ${metafieldsItems}
      }
      MF_description: metafield(
        namespace: "content"
        key: "description"
      ) {
          ${metafieldsItems}
      }
      MF_extraInfo: metafield(
        namespace: "component"
        key: "extraInfo"
      ) {
          ${metafieldsItems}
      }
      MF_quickLinks: metafield(
        namespace: "content"
        key: "quickLinks"
      ) {
          ${metafieldsItems}
      }
      MF_seoSetting: metafield(namespace: "seo", key: "setting") {
        ${metafieldsItems}
      }
      MF_pageType: metafield(namespace: "pageType", key: "category") {
        ${metafieldsItems}
      }
      MF_preRender: metafield(namespace: "build", key: "preRender") {
							${metafieldsItems}
      }
      MF_template: metafield(namespace: "template", key: "name") {
							${metafieldsItems}
      }
      MF_navSetting: metafield(namespace: "nav", key: "setting") {
							${metafieldsItems}
      }
      MF_data: metafield(
        namespace: "global"
        key: "data"
      ) {
          ${metafieldsItems}
      }
      MF_Firework: metafield(
        namespace: "component"
        key: "Firework"
      ) {
          ${metafieldsItems}
      }
      MF_component_order: metafield(namespace: "component", key: "_order") {
            ${metafieldsItems}
      }
    }
  }
`
