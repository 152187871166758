import { FetcherError } from '@commerce/utils/errors'

export function getError(errors: any[] | null, status = 500) {
  errors = errors ?? [{ message: 'Failed to fetch Shopify API' }]
  return new FetcherError({ errors, status })
}

export async function getAsyncError(res: Response) {
  const data = await res.json()
  return getError(data.errors, res.status)
}

const handleFetchResponse = async (res: Response) => {
  if (res.ok) {
    try {
      const result = await res.json()
      const { data, errors } = result
      if (errors && errors.length) {
        throw getError(errors, res.status)
      }

      return data
    } catch (error) {
      console.log(error)
    }
  }

  throw await getAsyncError(res)
}

export default handleFetchResponse
